<template>
  <div class="home">
    <vs-breadcrumb
        :items="bread"
    ></vs-breadcrumb>

    <div class="cattitle">
      Distributors & Resellers
    </div>

    <vs-row vs-w="12">
      <vs-col vs-w="6" vs-sm="12" v-for="ser in items" v-bind:key="ser.index">
        <div class="reseller-container">
          <div class="img-container"><img :src="ser.img" /></div>
          <div>
            <div class="ser-title">
              <div v-if="$i18n.locale === 'en'">
                {{ ser.title_en }}
              </div>
              <div v-else>
                {{ ser.title_tr }}
              </div>
            </div>
            <div class="ser-descr">
              <div v-if="$i18n.locale === 'en'" v-html="ser.descr_en"></div>
              <div v-else v-html="ser.descr_tr"></div>
            </div>
          </div>
        </div>
      </vs-col>

    </vs-row>


  </div>
</template>

<script>
// @ is an alias to /src

import {__HTTP} from "@/utils";

export default {
  name: 'Resellers',
  watch: {
  },
  filters: {
    strippedContent: function(string) {
      return string.replace(/<\/?[^>]+>/ig, " ");
    }
  },
  methods: {
    loadResellers() {
      let requestBody = JSON.stringify({
        get: "posts",
        category: 6,
        withoutLimit: true
      });
      __HTTP("?do=list",requestBody,null,res => {
        this.items = res.data;
        //console.log("AAA",res);
      });
    },
  },
  mounted() {
    this.loadResellers();
  },
  data() {
    return {
      bread: [
            {
              title: 'Home',
              url: '/'
            },
            {
              title: 'Distributors',
              url: '/resellers',
              active: true
            }
      ],
      items: [],
    }
  },
  components: {}
}
</script>

<style scoped>
.img-container {
  width: 100%;
}

.img-container img {
  width: 100%;
}
.ser-title {
  font-size: 2rem;
  color: black;
  font-weight: bold;
}

.ser-descr {
  font-size: 1.2rem;
  color: #2c3e50;
}
  .reseller-container {
    flex-direction: column;
    padding: 24px;
    margin: 20px;
    max-width: 350px;
    border: 1px solid #f3f3f3;
    margin-bottom: 10px;
    box-shadow: 0px 10px 29px -20px rgba(0,0,0,0.75);
  }
</style>
