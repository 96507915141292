<template>
  <div class="header">
    <div class="topbar">
      <div><a href="/become-a-partner">Become a partner</a></div>
      <!-- <div><a href="/pages/where-to-buy">Where to buy</a></div> -->
      <div><a href="/pages/contact-us">Support</a></div>
      <div>
        <vs-dropdown>
          <a class="a-icon" href="#">Language
            <vs-icon icon="expand_more"></vs-icon>
          </a>
          <vs-dropdown-menu>
            <vs-dropdown-item @click="changeLang('en')" class="lang-item">
              English
            </vs-dropdown-item>
            <vs-dropdown-item @click="changeLang('tr')" class="lang-item">
              Türkçe
            </vs-dropdown-item>

          </vs-dropdown-menu>


        </vs-dropdown>
      </div>
    </div>


    <vs-sidebar parent="body" default-index="1"  color="default" class="sidebarx" spacer v-model="active">

      <div class="header-sidebar"  slot="header">
        <router-link replace to="/"><img src="../assets/miraqleslogo.svg" /></router-link>
      </div>
      <div v-for="sidemenuitem in menuitems" v-bind:key="sidemenuitem.index">
        <vs-sidebar-item :to="sidemenuitem.href" :index="sidemenuitem.index" :icon="sidemenuitem.icon" >
          {{ $t(sidemenuitem.title) }}
        </vs-sidebar-item>
      </div>

      <div class="footer-sidebar" slot="footer">
        <div class="copyright">
          {{ $t('allrightsreserved')}} © miraqles 2020<br />
          {{ $t('poweredby') }} <a href="https://esite-iq.com" target="_blank">eSITE Information Technology LLC </a>
        </div>
      </div>

    </vs-sidebar>



    <div class="header-content">
      <div class="header-container">
        <div class="logo">
          <router-link replace to="/"><img src="../assets/miraqleslogo.svg" /></router-link>
        </div>

        <vs-button class="mobile-menu" @click="active=!active" color="default" type="filled">
          <vs-icon icon="menu" size="small" color="white"></vs-icon>
        </vs-button>


        <div class="mainmenu">
          <div v-for="menuitem in menuitems" v-bind:key="menuitem.index">
            <router-link :key="menuitem.index" :to="menuitem.href">{{ $t(menuitem.title) }}</router-link>
          </div>
        </div>
      </div>
    </div>

    <hooper class="mainslider" :settings="mainHomeSlider" style="height: 400px" v-if="renderSlider">
      <slide v-for="item in items" v-bind:key="item.index">
        <div class="slidecontainer" :style="{ backgroundImage: `url(${item.img})` }">
          <div class="slider-gradient">
            <div class="slidercontent">
              <div class="slider-title">
                <div v-if="$i18n.locale === 'en'">
                  {{ item.title_en }}
                </div>
                <div v-else>
                  {{ item.title_tr }}
                </div>
              </div>
              <div class="slider-subtitle">
                <div v-if="$i18n.locale === 'en'" v-html="item.descr_en" />
                <div v-else v-html="item.descr_tr" />
              </div>
              <div class="slider-action">
                <vs-button type="relief" class="morepadding">Learn more</vs-button>
              </div>
            </div>
          </div>
          <!-- <img :src="item.img" /> -->
        </div>
      </slide>

      <hooper-pagination slot="hooper-addons"></hooper-pagination>
    </hooper>


  </div>
</template>

<script>
import {Hooper, Pagination as HooperPagination, Slide} from "hooper";
import {__HTTP} from "@/utils";

export default {
  name: 'Header',

  methods: {
    loadSlider() {
      let requestBody = JSON.stringify({
        shortname: this.$route.params.shortname,
        get: "posts",
        category: 5,
        withoutLimit: true
      });

      __HTTP("?do=list",requestBody,null,res => {
        //console.log(res);
        this.items = res.data;
        this.renderSlider = true;
      });
    },
    changeLang(lang){
      this.$root.$i18n.locale = lang;
    },
  },
  mounted() {
    this.loadSlider();
  },
  created() {

  },
  data() {
    return {
      renderSlider:false,
      active: false,
      mainHomeSlider: {
        infiniteScroll: true,
        centerMode: true,
        autoPlay: true,
        wheelControl: false,
        playSpeed: 3500,
      },
      menuitems: [
        {
          title: 'home',
          href: '/',
          icon: 'home'
        },
        {
          title: 'products',
          href: '/products',
          icon: 'category'
        },
        {
          title: 'services',
          href: '/pages/services',
          icon: 'hardware'
        },
        {
          title: 'distributors',
          href: '/resellers',
          icon: 'edit_road'
        },
        {
          title: 'aboutus',
          href: '/pages/about-us',
          icon: 'info'
        },
        {
          title: 'contactus',
          href: '/pages/contact-us',
          icon: 'call'
        },
      ],
    }
  },

  props: {
    msg: String
  },
  components: {
    Hooper,
    Slide,
    HooperPagination
  }
}
</script>

<style scoped>


.mainmenu {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.mainmenu div a {
  margin-left: 0.8rem;
}

.slidecontainer {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.slider-gradient {
  width: 50%;
  height: 100%;
  position: absolute;
  background: rgb(255,255,255);
  background: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(0,0,0,1) 100%);
}
.lang-item {
 font-family: 'Ubuntu', Avenir, Helvetica, Arial, sans-serif;
  font-size: 0.8rem;
  padding-left: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 10px;
}

.slidercontent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  height: 100%;
  margin-left: 40px;
}
.showcase-container {
  padding: 10px;
  margin-right: 40px;
  display: block;
  background-color: #f3f3f3;
  border-radius: 10px;
  width: 100%;
}
.slider-title {
  font-size: 2.2rem;
  font-weight: bold;
  color: white;
}

.slider-subtitle {
  font-size: 1.4rem;
  color: white;
}


.header-container {
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: row;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 10px;
  padding-bottom: 10px;

}
.a-icon {
  outline: none;
  text-decoration: none !important;
  display: flex;
  align-items: center;
  justify-content: center;

}

.header-container .logo {
  max-height: 60px;
  transition: all ease-in-out 0.2s;
}


.header-container .logo img {
  max-height: 60px;
  transition: all ease-in-out 0.2s;
}
.header-content {
  position: absolute;
  width: 100%;
  top: 30px;
  z-index: 999;
  height: 80px;
  background: rgb(255,255,255);
  background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(0,0,0,1) 100%);

}


.topbar {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 30px;
  background-color: black;
}
.header {
  position: relative;
}
.topbar a {
  margin-right: 16px;
  margin-left: 10px;
  color: darkgray;
  text-decoration: none;
  font-size: 0.8rem;
  transition: all ease-in-out 0.2s;
}
</style>
