<template>
  <div class="home">
    <vs-breadcrumb
        :items="bread"
    ></vs-breadcrumb>

    <div class="cattitle">
      {{ cattitle }}
    </div>

    <vs-row vs-w="12" vs-align="flex-start" style="margin-top: 20px; ">
      <vs-col vs-lg="3" vs-sm="12" vs-md="3" vs-xs="12" >
          <div class="sidebar">

            <div class="filters">Filter results by:</div>
            <vs-collapse>
              <vs-collapse-item>
                <div slot="header">
                  Brands
                </div>
                <div class="checklists" v-for="brand in brands" v-bind:key="brand.id">
                  <div>
                    <vs-checkbox v-model="selectedBrands" :vs-value="brand.id">{{brand.title_en}}</vs-checkbox>
                  </div>

                </div>
              </vs-collapse-item>

            </vs-collapse>


            <div class="filters">Categories</div>
            <vs-collapse v-for="cat in categories" v-bind:key="cat.id">
              <vs-collapse-item>
                <div slot="header">
                  {{cat.title_en}}
                </div>
                <div class="checklists" v-for="tag in cat.sub" v-bind:key="tag.id">
                  <div>
                    <vs-checkbox :vs-value="tag.id" v-model="selectedCats">{{tag.title_en}}</vs-checkbox>
                  </div>


                </div>
              </vs-collapse-item>

            </vs-collapse>




            <div class="filters">Specifications</div>
            <vs-collapse>
              <vs-collapse-item v-for="cat in tags" v-bind:key="cat.id">
                <div slot="header">
                  {{cat.title_en}}
                </div>
                <div class="checklists"  v-for="tag in cat.tags" v-bind:key="tag.id">
                  <div>
                      <vs-checkbox v-model="selectedTags" :vs-value="tag.id">{{tag.title_en }} ({{tag.count}})</vs-checkbox>
                  </div>
                </div>
              </vs-collapse-item>

            </vs-collapse>

          </div>
      </vs-col>
      <vs-col class="pagecontent" vs-lg="9" vs-md="9" vs-sm="12" vs-xs="12">
       <div class="pagecontent-inner">
         <div class="numofrecords" style="display: none">
          Number of products: {{ numberofrecords }}
         </div>
         <div class="products-actions">
           <div class="sortby">
             <div style="margin-top: 10px; margin-bottom: 10px;">
               <vs-select
                   v-on:change="loadProducts()"

                   label="Sort by"
                   v-model="sortby">
                  <vs-select-item :text="item.text" v-bind:key="item.index" :is-selected.sync="item.isSelected" :value="item.value" v-for="item in sorters" />
               </vs-select>
             </div>
            </div>
            <div class="numofitems">
              <div>
                <vs-select
                    class="se"
                    v-on:change="loadProducts()"
                    label="Number of items"
                    v-model="numofitems">
                    <vs-select-item :text="item.text" v-bind:key="item.index" :is-selected.sync="item.isSelected" :value="item.value" v-for="item in numofitemsarray" />
                </vs-select>
              </div>
            </div>
         </div>

         <hr style="margin-bottom: 10px;" />

         <div class="pitems">

           <div class="pitem" v-for="item in items" v-bind:key="item.id" @click="viewItem(item)">

             <div class="pitem-img" :style='"background-image: url(" + item.img + ");"'></div>

             <div class="pitem-title"> {{item.title_en}}</div>
             <div class="pitem-partnum">Part number: {{ item.partnumber }}</div>
             <div class="pitem-descr">{{ item.descr_en && item.descr_en.length > 98 ? item.descr_en.substring(0,98)+"..." : item.descr_en}}</div>
             <vs-button class="pitem-button" @click="viewItem(item)" color="dark" type="border">VIEW DETAILS</vs-button>
           </div>
         </div>

         <div class="center-pagination">
           <vs-pagination :total="numOfPages" v-model="page"  @input="this.loadProducts"></vs-pagination>
         </div>
       </div>
      </vs-col>
    </vs-row>


  </div>
</template>

<script>
// @ is an alias to /src

import {__HTTP} from "@/utils";

export default {
  name: 'Products',
  watch: {
    selectedCats: {
      handler() {
        this.getTags();
        this.reloadPosts();
      }
    },
    selectedTags: {
      handler() {
        this.reloadPosts();
      }
    },
    selectedBrands:{
      handler() {
        this.reloadPosts()
      }
    }
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0,320);
    },
    viewItem(item){
      this.$router.push({ name: `itemview`, params: {id: item.id}});
    },
    reloadPosts() {
      this.page = 1;
      this.items = [];
      this.loadProducts();
    },
    loadProducts() {
      this.scrollToTop()
      let requestBody = JSON.stringify({get: "items",sort: this.sortby, selectedCats: this.selectedCats ,page: this.page, numOfItems: this.numofitems,selectedTags: this.selectedTags,selectedBrands: this.selectedBrands});
      __HTTP("?do=list",requestBody,null,res => {
        //console.log("posts",res);
        this.items = res.items;
        this.numOfPages = res.numberOfPages;
      });
    },
    loadCats() {
      let requestBody = JSON.stringify({get: "cats"});
      __HTTP("?do=list",requestBody,null,res => {
        //console.log("cats",res);
        this.categories = res;
        //this.numOfPages = res.numOfPages;
      });

    },
    getTags() {
        __HTTP("?do=list",JSON.stringify({get: "tags",selectedCats: this.selectedCats}),null,res => {
          this.tags = res;
        });
    },
    loadBrands() {
      let requestBody = JSON.stringify({get: "brands"});
      __HTTP("?do=list",requestBody,null,res => {
        //console.log("cats",res);
        this.brands = res;
        //this.numOfPages = res.numOfPages;
      });

    }
  },
  mounted() {
    this.loadProducts();
    this.loadCats();
    this.loadBrands();
  },
  data() {
    return {
      bread: [
            {
              title: 'Home',
              url: '/'
            },
            {
              title: 'Products',
              url: '/products',
              active: true
            }
      ],
      page: 1,
      selectedCats: [],
      numOfPages: 1,
      cattitle: 'Products',
      numberofrecords: '0',
      currentx: 0,
      item11: false,
      item21: false,
      item31: false,
      item1: false,
      item2: false,
      item3: false,
      item1x: false,
      item2x: false,
      item3x: false,
      sortby: 'Name (A-Z)',
      items: [],
      brands: [],
      tags: [],
      selectedTags: [],
      selectedBrands: [],
      numofitems: '50',
      numofitemsarray: [
        {text:'10',value:10, isSelected: false},
        {text:'25',value:25, isSelected: false},
        {text:'50',value:50, isSelected: true},
        {text:'100',value:100, isSelected: false}
      ],
      sorters:[
        {text:'Name (A-Z)',value:1, isSelected: true},
        {text:'Name (Z-A)',value:2, isSelected: false},
        {text:'Listing date (Older)',value:3, isSelected: false},
        {text:'Listing date (Newer)',value:4, isSelected: false},
        {text:'Partnumber (Ascending)',value:5, isSelected: false},
        {text:'Partnumber (Descending)',value:6, isSelected: false},
      ],

      categories: [
        {
          title: 'Explore our distribution centers',
          href: '/',
          src: 'box.svg'
        },
        {
          title: 'Discover our 10-years experience',
          href: '/products',
          src: 'engineer.svg'
        },
        {
          title: 'Know more about our service center',
          href: '/services',
          src: 'customer.svg'
        },
        {
          title: 'Become a miraqles partner',
          href: '/services',
          src: 'hand-shake.svg'
        },
      ],
      products: [
        {
          title: 'Explore our distribution centers',
          id: '/',
          src: 'box.svg'
        },
        {
          title: 'Discover our 10-years experience',
          id: '/products',
          src: 'engineer.svg'
        },
        {
          title: 'Know more about our service center',
          id: '/services',
          src: 'customer.svg'
        },
        {
          title: 'Become a miraqles partner',
          id: '/services',
          src: 'hand-shake.svg'
        },
      ],
      maintags: [
        {
          title: 'Explore our distribution centers',
        },
        {
          title: 'Discover our 10-years experience',
        },
        {
          title: 'Know more about our service center',
        },
        {
          title: 'Become a miraqles partner',
        },
      ],
    }
  },
  components: {}
}
</script>

<style>
  .sidebar {
    background-color: #f3f3f3;
    margin-bottom: 20px;
    margin-left: 20px;
  }
  .numofrecords {
    font-size: 1.2rem;
    color: #284156;
    font-weight: bold;
  }

  .cattitle {
    font-size: 2rem;
    font-weight: bold;
    color: black;
    margin-left: 20px;
    margin-right: 20px;
  }
  .pagecontent {

  }

  .center-pagination {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .checklists {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    justify-content: flex-start;
  }

  .checklists div {
    margin-top: 3px;
    margin-bottom: 3px;
  }
  .products-actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .filters {
    font-weight: bold;
    font-size: 1.2rem;
    margin-left: 20px;
    margin-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .vs-checkbox--check .material-icons {
    font-size: 16px !important;
  }
  .pitems {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    cursor: pointer;
  }

  .pagecontent-inner {
    padding-right: 20px;
    padding-left: 10px;
  }
  .numofitems .se {
    max-width: 150px !important;
  }
  .pitem {
    background-color: #f8f8f8;
    padding: 20px;
    min-height: 420px;
    width: 288px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 2px;
    margin-right: 2px;
    margin-top: 4px;
  }

  .pitem-img {
    height: 288px;
    width: 288px;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: white;
    border: 2px solid #f3f3f3;
    background-position: center center;
    margin-bottom: 10px;
  }

  .pitem-title {
    font-weight: bold;
    font-size: 0.9rem;
  }

  .pitem-descr {
    margin-top: 4px;
    font-size: 0.8rem;
    color: #646464
  }

  .pitem-partnum {
    margin-top: 4px;
    font-weight: bold;
    font-size: 0.8rem;
    color: #2c3e50;
  }

  .pitem-button {
    width: 100% !important;
    border-radius: 0px !important;
    margin-top: 10px;
    font-family: 'Ubuntu-Bold', Avenir, Helvetica, Arial, sans-serif !important;
    font-size: 0.8rem;
  }

  .vs-collapse {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
   }
  .con-vs-checkbox {
    justify-content: flex-start !important;

  }
</style>
