import Axios from 'axios';
export const FormHeader = {headers: {'Content-Type': "application/x-www-form-urlencoded"}}

var apiURL;

if (process.env.NODE_ENV === 'production') {
    apiURL = "/api.php";
} else {
    apiURL = "https://miraqles.com/api.php";
}
export const URL = apiURL;


export function __HTTP($endPoint,$body,$headers,$callBack) {
    if ($headers == null)
        $headers = FormHeader
    Axios.post(URL+$endPoint,$body,$headers).then((res) => {
        if (res.status == 200) {
            $callBack(res.data);
        } else {
            alert("");
        }
    })
}
