<template>
  <div class="home">
    <vs-breadcrumb
        :items="bread"
    ></vs-breadcrumb>



    <vs-row vs-w="12">
      <vs-col vs-w="12">
          <div class="img-container" :style='"background-image: url(" + img + ");"'>
            <div class="grad">
            <div class="cattitle">
              <div v-if="this.$i18n.locale === 'en'">
                {{ title_en }}
              </div>
              <div v-else>
                {{ title_tr }}
              </div>
            </div>
          </div>
          </div>
          <div>

            <div class="ser-descr">
              <div v-if="this.$i18n.locale === 'en'" v-html="descr_en">

              </div>
              <div v-else v-html="descr_tr">

              </div>
            </div>
          </div>
      </vs-col>

    </vs-row>


  </div>
</template>

<script>
// @ is an alias to /src

import {__HTTP} from "@/utils";

export default {
  name: 'Pages',
  watch: {
  },
  methods: {
    loadPages() {
      let requestBody = JSON.stringify({
        shortname: this.$route.params.shortname,
        get: "posts",
        category: 4
      });
      __HTTP("?do=list",requestBody,null,xres => {
        let res = xres.data[0];
        this.title_en = res.title_en;
        this.title_tr = res.title_tr;
        this.descr_en = res.descr_en;
        this.descr_tr = res.descr_tr;
        this.img = res.img;
        this.adddate = res.adddate;
        this.bread[1].title =  this.$i18n.locale === 'en' ? this.title_en : this.title_tr;
      });
    },
  },
  created() {
    this.$watch(
        () => this.$route.params,
        (toParams, previousParams) => {
          if (toParams !== previousParams) {
            this.loadPages();
          }
        }
    )
  },
  mounted() {
    this.loadPages();
  },
  data() {
    return {
      title_en: '',
      title_tr: '',
      descr_en: '',
      descr_tr: '',
      img: '',
      adddate: '',
      bread: [
            {
              title: 'Home',
              url: '/'
            },
            {
              title: this.$i18n.locale === 'en' ? this.title_en : this.title_tr,
              url: '/pages/' + this.$route.params.shortname,
              active: true
            }
      ],
    }
  },
  components: {}
}
</script>

<style scoped>
.img-container {
  min-height: 250px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  align-content: flex-end;
  background-size: cover;
  background-position: center center;
  max-height: 500px;
  box-shadow: 0px 10px 29px -20px rgba(0,0,0,0.75);
  border-bottom: 1px solid #f3f3f3;
  margin-bottom: 10px;
}

.grad {
  width: 100%;
  height: 50%;
  background: rgb(0,0,0);
  background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 100%);
}
.ser-title {
  font-size: 2rem;
  color: black;
  font-weight: bold;
}

.ser-descr {
  font-size: 1.2rem;
  color: #2c3e50;
  margin-top: 40px;
  margin-bottom: 40px;
  margin-left: 40px;
  margin-right: 40px;
}
.cattitle {
  font-size: 3rem;
  color: white;
  text-shadow: #292929 1px 2px 2px;
  margin-bottom: 10px;
  margin-left: 40px;
  margin-right: 40px;
}


</style>
