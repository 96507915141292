<template>
  <div class="footer">
    <div class="footer-container">
        <vs-row>
          <vs-col vs-justify="center" vs-align="center" vs-lg="4" vs-sm="12" vs-md="4" vs-xs="12">
            <div class="footer-container2">
              <strong style="font-size: 2rem;">miraqles</strong>

              <div class="socialicons">
               <div class="socialicon">
                 <a href="https://facebook.com" target="_blank"><font-awesome-icon :icon="['fab', 'facebook']" /></a>
               </div>

                <div class="socialicon">
                  <a href="https://youtube.com" target="_blank"><font-awesome-icon :icon="['fab', 'youtube']" /></a>
                </div>

                <div class="socialicon">
                  <a href="https://instagram.com" target="_blank"><font-awesome-icon :icon="['fab', 'instagram']" /></a>
                </div>

                <div class="socialicon">
                  <a href="https://linkedin.com" target="_blank"><font-awesome-icon :icon="['fab', 'linkedin']" /></a>
                </div>
              </div>
            </div>
          </vs-col>

          <vs-col vs-justify="center" vs-align="center" vs-lg="4" vs-sm="12" vs-md="4" vs-xs="12">
            <div class="footer-container2">
              <div style="font-size: 1rem; margin-bottom: 20px; font-weight: bold;">{{$t("aboutus")}}</div>
              <div class="abouttext">
                {{ $t("about") }}
              </div>
            </div>
          </vs-col>

          <vs-col vs-justify="center" vs-align="center" vs-lg="4" vs-sm="12" vs-md="4" vs-xs="12">
            <div class="footer-container2">
              <div style="font-size: 1rem; margin-bottom: 20px; font-weight: bold;">{{$t("pages")}}</div>
                  <vs-row>
                    <vs-col vs-justify="center" vs-align="center" vs-lg="6" vs-md="6" vs-sm="6" vs-xs="6">
                      <ul>
                        <li>
                          <a href="/">{{$t("home")}}</a>
                        </li>
                        <li>
                          <a href="/products">{{$t("products")}}</a>
                        </li>
                        <li>
                          <a href="/news">{{$t("news")}}</a>
                        </li>
                        <li>
                          <a href="/services">{{$t("services")}}</a>
                        </li>
                        <li>
                          <a href="/pages/about-us">{{$t("aboutus")}}</a>
                        </li>
                      </ul>
                    </vs-col>
                    <vs-col vs-justify="center" vs-align="center" vs-lg="6" vs-md="6" vs-sm="6" vs-xs="6">
                      <ul>
                        <li>
                          <a href="/become-a-partner">{{$t("becomeapartner")}}</a>
                        </li>
                        <li>
                          <a href="/resellers">{{$t("distributors")}}</a>
                        </li>
                        <li>
                          <a href="/pages/warranty">{{$t("warranty")}}</a>
                        </li>
                        <li>
                          <a href="/pages/contact-us">{{$t("contactus")}}</a>
                        </li>
                        <li>
                          <a href="/pages/privacy">{{$t("privacypolicy")}}</a>
                        </li>
                      </ul>
                    </vs-col>

                  </vs-row>

            </div>


          </vs-col>


        </vs-row>


      <div class="copyright">
        {{$t("allrightsreserved")}} © miraqles 2020<br />
        {{$t("poweredby")}} <a href="https://esite-iq.com" target="_blank">eSITE Information Technology LLC </a>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Foooter',
  props: {
    msg: String
  }
}
</script>

<style scoped>
.footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #292929;
  color: white;
  padding-top: 40px;
  padding-bottom: 40px;
}

.footer-container {
  width: 1368px;
  padding-top: 10px;
  padding-bottom: 00px;
  padding-right: 40px;
  padding-left: 40px;

}

.footer-container2 {
  margin-bottom: 10px;
  margin-top: 10px;
  margin-left: 30px;
  margin-right: 30px;
}
.footer-container2 ul li a{
  color: #c6c6c6;
  text-decoration: none;

  border-bottom: 1px dotted #c3c3c3
}

.footer-container2 ul li a:hover {
  color: #eaeaea;

}

.footer-container2 ul li {
  margin-bottom: 8px;
  list-style: none;
}
.socialicons {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  align-content: flex-start;
}

.socialicon {
  margin-right: 20px;
  display: block;
  max-width: 100px;
}
.socialicon a {
  color: #9c9c9c;
  font-size: 2rem;
  transition: all ease-in-out 0.1s;
  text-decoration: none;
}

.socialicon a:hover {
  color: #f3f3f3;
}
</style>
