<template>
  <div class="home">
    <vs-breadcrumb
        :items="bread"
    ></vs-breadcrumb>

    <div class="cattitle">
      Become a partner
    </div>

    <vs-row vs-w="12">
      <vs-col md="12">
       <div class="form-container">
         <div class="form">
           <div class="becomeimg">
             <img src="../assets/becomeapartner.jpg" />
           </div>
           <div class="becometext">
             miraqles represents an excellent partnership opportunity. With its great products it's easy to create quotes that impresses your clients and shows the strength of miraqles as both configurator and CPQ (Configure, Price, Quote). Its many products lets you adjust the offer to the needs of each customer.
           </div>
         </div>


         <div class="form">

           <form id="app" @submit="submitForm">
             <div v-if="errors.length" style="width: 100%; color: red; margin-bottom: 20px;">
               <b>Please correct the following error(s):</b>
             <ul>
               <li v-for="error in errors" v-bind:key="error.index">{{ error }}</li>
             </ul>
            </div>


           <div class="form-element">
              <div style="margin-bottom: 4px;">Fullname</div>
              <vs-input style="width: 100%" size="large" v-model="fullname"/>
           </div>

           <div class="form-element">
           <div style="margin-bottom: 4px;">Jobtitle</div>
           <vs-input style="width: 100%" size="large" v-model="jobtitle"/>
           </div>


           <div class="form-element">
           <div style="margin-bottom: 4px;">Company name</div>
           <vs-input style="width: 100%" size="large" v-model="company"/>
           </div>

           <div class="form-element">
           <div style="margin-bottom: 4px;">Address</div>
           <vs-input style="width: 100%" size="large" v-model="address"/>
           </div>


           <div class="form-element">
           <div style="margin-bottom: 4px;">Phone number</div>
           <vs-input style="width: 100%" size="large" v-model="phone"/>
           </div>

           <div class="form-element">
           <div style="margin-bottom: 4px;">Email address</div>
           <vs-input style="width: 100%" size="large" v-model="email"/>
           </div>


           <div class="form-element">
           <div style="margin-bottom: 4px;">Message</div>
           <vs-textarea style="width: 100%" size="large" v-model="message"/>
           </div>
           <vs-button color="primary" size="large" type="filled" @click="submitForm">Submit</vs-button>
           </form>
         </div>
       </div>
      </vs-col>


    </vs-row>


  </div>
</template>

<script>
// @ is an alias to /src

import {__HTTP} from "@/utils";

export default {
  name: 'BecomePartner',
  watch: {
  },
  filters: {
    strippedContent: function(string) {
      return string.replace(/<\/?[^>]+>/ig, " ");
    }
  },
  methods: {

    checkForm:function(e) {
      if(this.fullname && this.email && this.company && this.phone) return true;
      this.errors = [];
      if(!this.fullname) this.errors.push("Fullname is required.");
      if(!this.phone) this.errors.push("Phone is required.");
      if(!this.email) this.errors.push("Email is required.");
      if(!this.company) this.errors.push("Company name is required.");
      e.preventDefault();
    },
    submitForm() {
      this.errors = [];
      if (this.checkForm()) {
        let requestBody = JSON.stringify({
          form: "partners",
          fullname: this.fullname,
          jobtitle: this.jobtitle,
          email: this.email,
          phone: this.phone,
          company: this.company,
          address: this.address,
          message: this.message,

        });
        __HTTP("?do=sendemail",requestBody,null,res => {
          this.status = res.auth;
          console.log("AAA",res);
        });
      }
    },
  },
  mounted() {

  },
  data() {
    return {
      bread: [
            {
              title: 'Home',
              url: '/'
            },
            {
              title: 'Become a partner',
              url: '/become-a-partner',
              active: true
            }
      ],
      fullname: null,
      jobtitle: null,
      email: null,
      phone: null,
      company: null,
      address: null,
      message: null,
      errors:[],
    }
  },
  components: {}
}
</script>

<style scoped>
.form-container {
  flex-direction: row;
  flex: 1;
  display: flex;
  padding: 24px;
  margin: 20px;
  border: 1px solid #f3f3f3;
  margin-bottom: 10px;
  box-shadow: 0px 10px 29px -20px rgba(0,0,0,0.75);
}

.form-element {
  margin-bottom: 4px;
  width: 100%;
}
.becomeimg img {
  width: 100%;
  border-radius: 4px;
  box-shadow: 0px 10px 29px -20px rgba(0,0,0,0.75);
}

.becomeimg {
  margin-bottom: 10px;
}
.becometext {
  font-weight: bold;
  font-size: 20px;
}
.form {
  width: 50%;
  margin: 20px;
}

@media only screen and (max-width: 768px) {
  .form-container {
    flex-direction: column;
    flex: 1;
    display: flex;
  }
  .form {
    width: 100%;
  }
}
</style>

