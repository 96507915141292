import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    bread: [
      {
        title: 'Home',
        url: '/'
      },
      {
        title: 'Pages',
        url: '/pages/',
        active: true
      }
    ],
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
