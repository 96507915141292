import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuesax from 'vuesax'
import i18n from "./i18n";

Vue.config.productionTip = false


import 'vuesax/dist/vuesax.css' //Vuesax styles
import 'material-icons/iconfont/material-icons.css';
import 'animate.css'; // Animations
import 'vue-inner-image-zoom/lib/vue-inner-image-zoom.css'


import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faYoutube, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'


export const eventBus = new Vue();
Vue.use(Vuesax, {
  theme:{
    colors:{
      primary:'#1E477D',
      success:'rgb(23, 201, 100)',
      danger:'rgb(242, 19, 93)',
      warning:'rgb(255, 130, 0)',
      dark:'rgb(36, 33, 69)'
    }
  }
})

library.add(faUserSecret, faInstagram, faLinkedin, faFacebook, faYoutube)


Vue.component('font-awesome-icon', FontAwesomeIcon)


new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
