import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Products from '../views/Products.vue'
import Services from '../views/Services.vue'
import Projects from '../views/Projects.vue'
import Resellers from '../views/Resellers.vue'
import Pages from '../views/Pages.vue'
import ItemView from '../views/ItemView.vue'
import BecomePartner from '../views/BecomePartner.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/item/:id',
    name: 'itemview',
    component: ItemView
  },
  {
    path: '/products/:id?',
    name: 'products',
    component: Products,
  },
  {
    path: '/services',
    name: 'services',
    component: Services,
  },
  {
    path: '/become-a-partner',
    name: 'BecomePartner',
    component: BecomePartner,
  },
  {
    path: '/projects',
    name: 'projects',
    component: Projects,
  },
  {
    path: '/resellers',
    name: 'resellers',
    component: Resellers,
  },
  {
    path: '/pages/:shortname',
    name: 'pages',
    component: Pages,
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  base: process.env.BASE_URL,
  routes
})

export default router
