<template>
  <div id="app">
      <div class="maincontainer">
      <Header />
        <router-view />
      </div>
      <Footer />
  </div>
</template>

<style>
* {
  box-sizing: content-box;
}
body {
  background-color: #f3f3f3;
}
#app {
  font-family: 'Ubuntu', Avenir, Helvetica, Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  display: flex;
  flex-direction: column;
  justify-items: center;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.maincontainer {
  width: 1368px;
  min-height: 100vh;
  background-color: white;
  box-shadow: 0px 2px 29px -13px rgba(0,0,0,0.25);
}
.footer-sidebar {
  background-color: #292929;
  padding-bottom: 30px;
  font-family: 'Ubuntu', Avenir, Helvetica, Arial, sans-serif;
  padding-right: 10px;
  padding-left: 10px;
}
.copyright {
  border-top: 1px solid #4c4c4c;
  margin-top: 20px;
  padding-top: 30px;
  text-align: center;
  color: #c4c4c4;
  font-size: 0.8rem;
}
.copyright a {
  color: #c4c4c4;
  font-weight: bold;
  text-decoration: none;
}


.mobile-menu {
  display: none;
}
.vs-sidebar--header {
  padding: 0 !important;

}
.header-sidebar {
  margin: 0px;
  background-color: #292929;
  padding: 20px;
}
.vs-sidebar--items {
  font-family: 'Ubuntu', Avenir, Helvetica, Arial, sans-serif;
  font-size: 1rem;
}
.header-sidebar img {
  max-width: 80%;
  margin-right: 10px;
  margin-left: 10px;
}

.a-icon {
  margin-top: 4px;
}

@media only screen and (max-width: 1368px) {
  .mobile-menu {
    display: block;
  }
  .maincontainer ,.slider-gradient {
    width: 100% !important;
  }
  .blue-container {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .home-boxes-container .home-box {
    margin-left: 0px !important;
    margin-right: 0px !important;;
  }

  .mainmenu {
    display: none !important;
  }


  .header-container .logo {
    max-height: 40px !important;;
  }


  .header-container .logo img {
    max-height: 40px !important;
  }


}


@media only screen and (max-width: 768px) {
  .product-metadata {
    padding-left: 20px;
  }



  .mainimg-container {
    margin-right: 20px !important;
  }

  .pitem {
    width: 220px !important;
  }

  .pitem-img {
    width: 180px !important;
    height: 180px !important;
  }

  .topbar a {
    font-size: 0.7rem !important;
    margin-left: 2px !important;
    margin-right: 2px !important;
  }

  .rightbox {
    margin-right: 0px !important;
  }

  .home-boxes-container {
    width: 220px !important;
  }

  .homebloxcontainer {
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
  }

  .footer-container {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

}

@media only screen and (max-width: 699px) {

  .home-boxes-container {
    width: 180px !important;
  }
  .products-actions {
    display: flex;
    flex-direction: column !important;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    margin-bottom: 20px;
  }

  .pitem {
    width: 180px !important;
    padding: 8px !important;
  }

  .pitem-img {
    width: 160px !important;
    height: 160px !important;
  }



  .maincatItem {

    height: 300px !important;
    width: 100% !important;

  }

  .maincat-title {

    width: 100% !important;


  }
  .productshooper {
    height: 300px !important;
  }

}


@media only screen and (max-width: 359px) {

  .pitem {
    width: 100px !important;
    padding: 4px !important;
  }

  .pitem-img {
    width: 80px !important;
    height: 80px !important;
  }


}


@media only screen and (min-width: 360px) and (max-width: 699px) {

  .pitem {
    width: 160px !important;
    padding: 4px !important;
  }

  .pitem-img {
    width: 150px !important;
    height: 150px !important;
  }


}



@media only screen and (min-width: 1000px) and (max-width: 1270px) {

.itemimgwrapper {
  height: auto !important;
  width: 400px !important;
}
}


@media only screen and (min-width: 900px) and (max-width: 1000px) {

  .itemimgwrapper {
    height: auto !important;
    width: 330px !important;
  }
}

@media only screen and (max-width: 718px) {

  .itemimgwrapper {
    height: auto !important;
    width: 100% !important;
  }
}





</style>
<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
export default {
  components: {Header, Footer}
}
</script>
