<template>
  <div class="home">

    <vs-row vs-w="12" vs-align="center" style="margin-top: 40px; ">
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="6" vs-sm="12" vs-md="12" vs-xs="12" >
          <div class="homebloxcontainer">

              <div v-for="homeblock in homeblocks" v-bind:key="homeblock.index" class="home-boxes-container">
                <div class="home-box" :to="homeblock.href">
                    <img :src="require(`../assets/${homeblock.src}`)" />

                </div>
                <div class="home-box-text">{{ homeblock.title }}</div>
              </div>

          </div>
      </vs-col>

      <vs-col vs-justify="center" vs-align="center" vs-lg="6" vs-md="6" vs-sm="6" vs-xs="12">
        <div  class="rightbox" style="flex: 1; flex-direction: column; justify-content: center; display: flex; align-items: center;">
              <hooper style="height: 360px; background-color: #f3f3f3">
                <slide v-for="mainproduct in mainproducts" v-bind:key="mainproduct.index">
                  <div class="mainproductItem" :style='"background-image: url(" + mainproduct.img + ");"'>
                    <div class="mainproduct-title">
                      {{ mainproduct.title_en }}
                    </div>
                  </div>
                </slide>
                <hooper-pagination slot="hooper-addons"></hooper-pagination>

              </hooper>
          <div>
            <vs-button type="relief" @click="goToProducts()" class="morepadding">Checkout our products</vs-button>
          </div>

        </div>

      </vs-col>
    </vs-row>

  <div class="grey-section">
    <div class="heading" style="margin-top: 20px;">
      Fiber Optic & Network Equipment Products
    </div>

    <hooper :settings="hooperSettingsProducts" class="productshooper">
      <slide v-for="maincat in maincats" v-bind:key="maincat.index">
        <div class="maincatItem" :style='"background-image: url(" + maincat.img + ");"'>
          <div class="maincat-title">
            <div>{{ maincat.title_en }}</div>
          </div>
        </div>
      </slide>
    </hooper>


  </div>

  <div class="blue-section">
    <vs-row  vs-justify="center" vs-align="center">
        <vs-col vs-justify="center" vs-align="center" vs-lg="4" vs-sm="12" vs-md="4" vs-xs="12">
          <div class="blue-container">
            <div class="blue-icon"><img src="../assets/pdf-file.svg" /></div>
            <div class="blue-text">
              <strong>Need more details ?</strong><br>
              check technical specifications
            </div>
          </div>
        </vs-col>

        <vs-col vs-justify="center" vs-align="center" vs-lg="4" vs-sm="12" vs-md="4" vs-xs="12">
          <div class="blue-container">
          <div class="blue-icon"><img src="../assets/guarantee.svg" /></div>
          <div class="blue-text">
            <strong>Warranties</strong><br>
            more than 2 years
          </div>
          </div>
        </vs-col>

        <vs-col vs-justify="center" vs-align="center" vs-lg="4" vs-sm="12" vs-md="4" vs-xs="12">
          <div class="blue-container">
          <div class="blue-icon"><img src="../assets/support.svg" /></div>
          <div class="blue-text">
            <strong>Need help ?</strong><br>
            contact our support engineer
          </div>
          </div>
        </vs-col>
    </vs-row>
  </div>


  </div>
</template>

<script>
// @ is an alias to /src
import { Hooper, Slide , Pagination as HooperPagination} from 'hooper';
import 'hooper/dist/hooper.css';
import {__HTTP} from "@/utils";

export default {
  name: 'Home',
  created(){
    this.loadMainCats();
    this.loadMainProducts();
  },
  methods: {
    loadMainProducts() {
      let requestBody = JSON.stringify({
        get: "posts",
        category: 7,
        withoutLimit: true
      });
      __HTTP("?do=list",requestBody,null,res => {
        this.mainproducts = res.data;
      });
    },
    loadMainCats() {
      let requestBody = JSON.stringify({
        get: "posts",
        category: 8,
        withoutLimit: true
      });
      __HTTP("?do=list",requestBody,null,res => {
        this.maincats = res.data;
      });
    },
    goToProducts() {
      this.$router.push({ name: "products"});
    }
  },
  data() {
    return {
      slideshow: [
        {
          title: 'Fiber optic systems',
          subtitle: 'The best choice for your business',
          href: '/products/fiberoptic-systems',
          src: 'box.svg'
        },
        {
          title: 'Fiber optic systems',
          subtitle: 'The best choice for your business',
          href: '/products/fiberoptic-systems',
          src: 'box.svg'
        },
        {
          title: 'Fiber optic systems',
          subtitle: 'The best choice for your business',
          href: '/products/fiberoptic-systems',
          src: 'box.svg'
        },
      ],
      homeblocks: [
        {
          title: 'Explore our distribution centers',
          href: '/',
          src: 'box.svg'
        },
        {
          title: 'Discover our 10-years experience',
          href: '/products',
          src: 'engineer.svg'
        },
        {
          title: 'Know more about our service center',
          href: '/services',
          src: 'customer.svg'
        },
        {
          title: 'Become a miraqles partner',
          href: '/services',
          src: 'hand-shake.svg'
        },
      ],
      mainproducts: [],
      maincats: [],
      hooperSettingsProducts: {
        infiniteScroll: true,
        centerMode: false,
        autoPlay: true,
        wheelControl: false,
        playSpeed: 3500,
        breakpoints: {
          1298: {
            itemsToShow: 4
          },
          999: {
            itemsToShow: 3
          },
          700: {
            itemsToShow: 2
          },
          0: {
            itemsToShow: 1
          }
        }
      }

    }
  },
  components: {
    Hooper,
    Slide,
    HooperPagination
  }
}
</script>

<style>
.rightbox {
  margin-right: 40px;
}
.mainslider {
  background-color: black;
}

.heading {
  font-size: 1.4rem;
  margin-top: 10px;
  color: black;
  font-weight: bold;
}
.slidecontainer {
  background-color: black;
  height: 100%;
  position: relative;
}

.grey-section {
  background-color: #F7F7F7;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-right: 40px;
  padding-left: 40px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.blue-section {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-right: 40px;
  padding-left: 40px;
  padding-top: 60px;
  padding-bottom: 60px;
  background: #043472;
  background: linear-gradient(90deg, #043472 0%, #1E477D 100%);

}

.homebloxcontainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
}

.blue-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.blue-icon {
  width: 80px;
  height: 80px;
  margin-right: 20px;
  margin-left: 20px;
}

.blue-icon img {
  width: 100%;
  height: 100%;
}

.blue-text {
  font-size: 1.2rem;
  color: white;
}

.home-boxes-container {
  width: 300px;
  padding: 10px;
  justify-content: center;
  align-content: center;
}
.home-box img {
  max-height: 60px;
  max-width: 60px;
}

.home-boxes-container .home-box {
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;

  margin-left: 40px;
  margin-right: 40px;
  min-height: 140px;
  border-radius: 10px;
  background-color: #F9B800;
  border-bottom: 3px solid #8a6500;

}

.morepadding {
  margin-top: 20px;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 60px;
  padding-left: 60px;
  font-size: 1rem;
  font-weight: bold;
  font-family: 'Ubuntu', Avenir, Helvetica, Arial, sans-serif !important;
}

.home-box-text {
  font-family: 'Ubuntu-Bold', Avenir, Helvetica, Arial, sans-serif;
  font-size: 1rem;
  color: #284156;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
}
.mainproductItem {
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 300px;
  background-color: white;
  box-shadow: 0px 2px 29px -13px rgba(0,0,0,0.25);
  border-radius: 10px;
  margin: 10px;
}
.mainproductItem img {
  height: 100%;
  width: 100%;
  position: relative;
}

.mainproduct-title {
  background-color: rgba(0,0,0,0.5);
  color: white;
  font-size: 1rem;
  padding: 10px;
  padding-bottom: 16px;
  width: 100%;
  position: absolute;
  bottom: 0px;

}


.maincatItem {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  flex-direction: column;
  display: flex;
  flex: 1;
  justify-content: stretch;
  align-content: stretch;
  height: 200px;
  width: 300px;
  margin-right: 10px;
  margin-left: 10px;
  box-shadow: 0px 2px 29px -13px rgba(0,0,0,0.25);
  box-sizing: border-box;
  background-color: white;
}
.maincat img {
  height: 100%;
  width: 100%;
  position: relative;
}

.productshooper {
  margin-top: 20px;
}

.maincat-title {
  background: rgb(255,255,255);
  background: linear-gradient(180deg, rgba(255,255,255,0) 20%, rgba(0,0,0,0.6) 100%);
  color: white;
  font-size: 1rem;
  position: absolute;
  width: 300px;
  bottom: 0px;
  height: 35%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-content: flex-end;

}

.maincat-title div {
  margin: 20px;
  font-weight: bold;
}


.header-container div a {
  font-weight: bold;
  text-decoration: none;
  color: white;
  font-size: 1.2rem;
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.6);
}


</style>
