<template>
  <div class="home">
    <vs-breadcrumb
        :items="bread"
    ></vs-breadcrumb>

    <div class="fullitemmainflex">
        <div class="itemimgwrapper">
          <div class="mainimg-container">
              <inner-image-zoom v-if="mainitem.mainimg !== undefined" :src="mainitem.mainimg" />
          </div>
          <div>
            <hooper :settings="hooperItemView" style="height: 140px;">
              <slide v-for="xsubimg in mainitem.subimgs" v-bind:key="xsubimg.index">
                <div @click="replaceImg(xsubimg.url)" class="subimgx-img" :style='"background-image: url(" + xsubimg.url + ");"'></div>
              </slide>
              <hooper-pagination slot="hooper-addons"></hooper-pagination>

            </hooper>
          </div>
        </div>

        <div class="metadatawrapper">
          <div class="product-metadata">
            <div class="pitem-title">
              <div v-if="this.$i18n.locale === 'en'">
              {{ mainitem.title_en }}
              </div>
              <div v-else>
              {{ mainitem.title_tr }}
              </div>
            </div>
            <div class="scattitle">{{ mainitem.cattitle }}</div>
            <div class="partnumbrand">
              <div class="pitem-partnum">Part number: {{ mainitem.partnumber}}</div> | <div class="brand"><a :href="mainitem.brandurl">{{ mainitem.brandname }}</a> </div>
            </div>
            <div class="pitem-descr">
              <div v-if="this.$i18n.locale === 'en'">
                {{ mainitem.descr_en }}
              </div>
              <div v-else>
                {{ mainitem.descr_tr }}
              </div>
            </div>
            <vs-button class="pitem-button2" color="dark" type="border" @click="goToDealers()">WHERE TO BUY</vs-button>

            <div class="mainhead">Technical Specs:</div>
            <div class="subhead" v-html="mainitem.technicalspecs" />
            <div class="sep"></div>

            <div class="mainhead">Applications:</div>
            <div class="subhead" v-html="mainitem.applications" />
            <div class="sep"></div>

            <div class="mainhead">Datasheet:</div>
            <div class="subhead">
              <vs-button @click="getPDF(mainitem.datasheet)" class="pitem-button2" color="dark" type="border">
                <div class="partnumbrand">
                <vs-icon icon="download" style="margin-right: 4px;"></vs-icon>
                DOWNLOAD PDF</div></vs-button>

              </div>
            <div class="sep"></div>


          </div>
        </div>
    </div>
  </div>
</template>

<script>

import {__HTTP} from "@/utils";

import {Hooper, Pagination as HooperPagination, Slide} from 'hooper';
import 'hooper/dist/hooper.css';
import InnerImageZoom from 'vue-inner-image-zoom'

export default {
  name: 'ItemView',
  methods: {
    replaceImg(url) {
      this.mainitem.mainimg = url;
    },
    getPDF(url) {
      window.open(url);
    },
    goToDealers() {
      this.$router.push({ name: `resellers`});
    },
    getItem() {
      __HTTP("?do=getitem",JSON.stringify({id: this.$route.params.id}),null,res => {
        //console.log('item',res);
        if (res.auth) {
          this.mainitem = res;
          this.cattitle = res.cattitle;
          this.bread[2] = {title: this.mainitem.cattitle,url: '/products/' + this.mainitem.parent};
          this.bread[3] = {title: this.mainitem.title_en,active:true};
        } else {
          console.log("404 this item is not found");
        }
      });
    }
  },
  created() {
    this.getItem();
  },
  data() {
    return {
      hooperItemView: {
        infiniteScroll: false,
        wheelControl: false,
        autoPlay: false,
        itemsToShow: 3
      },
      bread: [
        {
          title: 'Home',
          url: '/'
        },
        {
          title: 'Products',
          url: '/products',

        },
        {
          title: 'Cat Title',
          url: '/products/cat',
        },
        {
          title: 'Item Title',
          active: true
        }
      ],
      cattitle: '',
      mainitem: {},
      mainimg: '',
    }
  },
  components: {
    Hooper,
    InnerImageZoom,
    HooperPagination,
    Slide
  }
}
</script>

<style scoped>
.fullitemmainflex {
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}

.metadatawrapper {
  flex: 1;
  display: flex;
}
.product-metadata {
  padding-right: 20px;
  padding-left: 20px;
}

.pitem-title {
  font-size: 2rem;
}

.pitem-partnum {
  font-size: 1.5rem;
}
.pitem-descr {
  font-size: 1rem;
}

.itemimgwrapper {
  height: 500px;
  width: 500px;
  display: block;
  padding-left: 20px;
  padding-right: 20px;
}

.mainimg-container {
  border: 1px solid #b7b7b7;
  margin-bottom: 10px;
  height: auto;
  width: 100%;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
}
.mainimg-container img {
  height: 100%;
  width: 100%;
}
.bigmainimg {
  width: 100%;
  max-height: 100%;
}

.subimg {
  border: 1px solid #9c9c9c;
  height: 120px;
  width: 120px;
}

.pitem-button2 {
  border-radius: 0px !important;
  margin-top: 14px;
  margin-bottom: 14px;
  font-family: 'Ubuntu-Bold', Avenir, Helvetica, Arial, sans-serif !important;
  padding-right: 20px;
  padding-left: 20px;
  font-size: 1.2rem;
}

.pitem-descr {
  font-size: 1.2rem;
}

.partnumbrand { display: flex; flex-direction: row; align-items: center;
}
.pitem-partnum {
  font-size: 1.2rem;
  margin-right: 8px;
}

.scattitle {
  font-size: 1.2rem;
  color: #1E477D;
}
.mainhead {
  font-size: 1.4rem;
  font-weight: bold;

}

.sep {
  margin-top: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #f3f3f3;
}
.subhead {
  font-size: 1.1rem;
  color: #414141
}

.brand a {
  font-size: 1.2rem;
  color: #284156;
  font-weight: bold;
  margin-left: 8px;
}
.subimgx-img  {

  display: block;
  background-size: contain;
  cursor: pointer;
  background-position: center center;
  background-color: white;
  background-repeat: no-repeat;
  width: 100%;
  height: 120px;
}
</style>
